<template>
    <div class="image-list">
        <div v-if="list.length > 0" class="fx-column">
            <div class="fx-row fx-middle fx-center fx-around">
                <van-icon v-if="list.length > 1" :name="require('./icons/toggle_left.png')" size="24" @click="change('left')"></van-icon>
                <div class="qr-code__wrap fx-row">
                    <div class="qr-code--image__wrap fx-center">
                        <van-image :src="list[active]" :height="height" :width="width" @click="onClick"></van-image>
                    </div>
                </div>
                <van-icon v-if="list.length > 1" :name="require('./icons/toggle_right.png')" size="24" @click="change('right')"></van-icon>
            </div>
            <div class="fx-center-middle" style="margin-top: 15px;color:#F4657D;font-size: 14px;">
                此订单共有{{ list.length }}张取票码，当前第{{ active + 1 }}张
                <!-- {{(active + 1) + '/' + list.length}} -->
            </div>
        </div>
        <div v-else class="fx-center">
            <template v-if="loading">
                <div class="order_detail-ticket fx-center">正在出票中请耐心等待...</div>
                <!-- 预计{{chupiaoTime}}分钟出票,若出票失败将为您退款-->
                <div style="font-size: 12px;color: #dd6161">{{chupiaoTime}}</div>
            </template>
            <template v-else>
                <div class="order_detail-ticket fx-center">暂无凭证</div>
            </template>
            <!-- {{loading ? '正在出票中请耐心等待...' : '暂无凭证'}} -->
        </div>
    </div>

</template>

<script>
export default {
    props: {
        list: {
            default() {
                return []
            }
        },
        loading: {
            default() {
                return false;
            }
        },
        height: {
            default() {
                return 100
            }
        },
        width: {
            default() {
                return 100;
            }
        }
    },
    computed: {
        chupiaoTime() {
            return this.$store.getters.baseData.chupiaoTime;
        }
    },
    data() {
        return {
            active: 0,
        }
    },
    methods: {
        change(direction) {
            if (direction == 'left') {
                if (this.active == 0) {
                    this.active = this.list.length - 1
                } else {
                    this.active--;
                }
            } else {
                if (this.active == this.list.length - 1) {
                    this.active = 0;
                } else {
                    this.active++;
                }
            }
        },
        onClick() {
            this.$emit('on-click', this.active);
        }
    }
}
</script>

<style lang="scss" scoped>
.order_detail-ticket {
	border: 1px solid #dededf;
	color: $second-color;
	width: 88%;
	padding: 10px;
	font-size: 14px;
	border-radius: 4px;
	margin: 4px;
}
</style>

<template>
    <div class="ticket-code-swpier">
        <div v-if="list.length > 0" class="fx-column">
            <div class="fx-row fx-middle fx-center fx-around">
                <van-icon v-if="list.length > 1" name="arrow-left" size="24" @click="change('left')"></van-icon>
                <div class="qr-code__wrap fx-row">
                    <div class="qr-code--image__wrap fx-center">
                        <van-image :src="current.image" :height="height" :width="width" @click="onPreview"></van-image>
                    </div>
                </div>
                <van-icon v-if="list.length > 1" name="arrow" size="24" @click="change('right')"></van-icon>
            </div>
            <div v-if="showTicketInfo" class="ticket-code__info fx-center">
                <div class="ticket-code__text-wrap">
                    <div>手机号:{{current.mobile}}</div>
                    <div>验证码:{{ current.text }}</div>
                </div>
            </div>

            <div class="ticket-code__count fx-center-middle">
                此订单共有{{ list.length }}张取票码，当前第{{ active + 1 }}张
            </div>
        </div>
        <div v-else class="fx-center">
            <template v-if="loading">
                <div class="ticket-code__text-wrap">正在出票中请耐心等待...</div>
                <div class="delay-remark">{{ chupiaoTime }}</div>
            </template>
            <template v-else>
                <div class="ticket-code__text-wrap">暂无凭证</div>
            </template>
        </div>
        <van-image-preview v-model="show" :images="images" :start-position="previewIndex">
        </van-image-preview>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            default() {
                return []
            }
        },
        loading: {
            default() {
                return false;
            }
        },
        height: {
            default() {
                return 120
            }
        },
        width: {
            default() {
                return 100;
            }
        }
    },
    computed: {
        chupiaoTime() {
            return this.$store.getters.baseData.chupiaoTime;
        },
        current() {
            return this.list[this.active] || {}
        },
        showTicketInfo() {
            return this.current?.mobile && this.current?.text ? true : false
        },
        images() {
            return this.list.map(item => item.image);
        }
    },
    data() {
        return {
            active: 0,
            show: false,
            previewIndex: 0,
        }
    },
    methods: {
        onPreview() {
            this.show = true;
            this.previewIndex = this.active;
        },
        change(direction) {
            if (direction == 'left') {
                if (this.active == 0) {
                    this.active = this.list.length - 1
                } else {
                    this.active--;
                }
            } else {
                if (this.active == this.list.length - 1) {
                    this.active = 0;
                } else {
                    this.active++;
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.ticket-code__text-wrap {
	border: 1px solid #dededf;
	color: $second-color;
	width: 88%;
	padding: 10px;
	font-size: 14px;
	border-radius: 4px;
	margin: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.delay-remark {
	font-size: 12px;
	color: #f4657d;
}

.ticket-code__count {
	margin-top: 15px;
	color: #f4657d;
	font-size: 14px;
}

.ticket-code__info {
    margin-top: 15px;
}
</style>

<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box" :style="`background-color: ${'#F5B225'}`">
                <div class="fx-row fx-between fx-center">
                    <span class="order_detail-status">{{order.orderStatusText}}</span>
                    <div></div>
                    <van-icon name="delete-o" size="20" @click="confirmDelete"></van-icon>
                </div>
                <div class="order_detail-content--wrap">
                    <div class="fx-row fx-between">
                        <div class="order_detail-info fx-fill fx-column fx-between">
                            <span class="order_detail-name">{{order.movieName}}</span>
                            <div>
                                <span>{{order.playTime}}</span>
                                <span>{{order.edition}}</span>
                            </div>
                            <span>{{order.cinemaName}}</span>
                            <span class="fc-tips">{{order.seats}}</span>
                        </div>
                        <div>
                            <van-image :src="order.logo" width="80" height="120" class="image-round"></van-image>
                        </div>
                    </div>
                    <div v-if="order.orderstatus != 'R'">
                        <!-- <div>{{order.orderstatus}}</div>
                        <div>{{ticket}}</div> -->
                        <van-tabs v-model="activeTicket" :scrollspy="false" lazy-render sticky :ellipsis="false" animated>
                            <van-tab title="取票凭证">
                                <div class="fx-center" style="padding-top: 15px;">
                                    <template v-if="order.orderStatus == 'S' && Object.keys(ticket).length > 0">
                                        <div style="margin-bottom: 8px;">
                                            <qr-code :list="ticketValue"></qr-code>
                                        </div>
                                    </template>
                                    <template v-if="order.orderStatus == 'T' && Object.keys(ticket).length < 1">
                                        <div class="order_detail-ticket fx-center">正在出票中请耐心等待...</div>
                                        <!-- 预计{{chupiaoTime}}分钟出票,若出票失败将为您退款-->
                                        <div style="font-size: 12px;color: #dd6161">{{chupiaoTime}}</div>
                                    </template>
                                    <template v-else-if="order.orderStatus == 'S' && Object.keys(ticket).length > 0">
                                        <div class="order_detail-ticket fx-center">
                                            <div v-for="item, index in ticket" :key="index">
                                                <template v-for="temp, idx in item">
                                                    <span :key="idx">
                                                        <span>{{temp.name}}：</span>
                                                        <span>{{temp.value}}</span>
                                                    </span>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </van-tab>
                            <van-tab title="取票凭证" v-if="ticketImages.length > 0">
                                <div style="padding-top: 15px;">
                                    <image-list :list="ticketImages" @on-click="onPreview" :loading="order.orderStatus == 'T'"></image-list>
                                </div>
                            </van-tab>
                        </van-tabs>
                    </div>
                    <div v-if="!emptyBmh">
                        <div class="order_detail-name">小食取票码</div>
                        <div style="padding-top: 15px;">
                            <ticket-code-swiper :list="bmhTicketImages" :loading="order.orderStatus == 'T'" width="240"></ticket-code-swiper>
                        </div>
                    </div>
                    <div class="fx-around" v-if="order.refundReason && order.orderStatus == 'R'">
                        <div class="order_detail-name">退款原因</div>
                        <div class="fc-active">{{order.refundReason}}</div>
                    </div>
                    <div class="fx-around">
                        <div class="order_detail-name">{{order.cinemaName}}</div>
                        <div class="fc-tips">{{order.cinemaAddress}}</div>
                    </div>
                    <div class="fx-around">
                        <span style="padding-bottom: 5px;">
                            <span class="order_detail-name">实付金额：￥{{order.amount}} {{order.orderStatus == 'R' ? '(已退款)' : ''}} </span>
                            <span class="fs-9 fc-status">{{order.discount ? `(已优惠${order.discount})` : ''}}</span>
                        </span>
                        <span class="fc-tips" style="padding-bottom: 5px;">订单号： {{order.id}}</span>
                        <span class="fc-tips" style="padding-bottom: 5px;">下单时间： {{order.addTime}}</span>
                        <span class="fc-tips" style="padding-bottom: 5px;">手机号： {{order.mobile | hideMobile}}</span>
                        <div class="fx-row fx-between" style="padding-bottom: 5px;">
                            <span class="fc-tips">支付方式： {{order.payMethodText}}</span>
                            <!-- <span v-if="order.showRefundApply" class="refund" @click="goApplyRefund">申请退款</span> -->
                        </div>
                    </div>
                    <div class="fx-around">
                        <span class="order_detail-name">观影须知</span>
                        <p class="fc-tips">
                            1.请提前到达影院现场，找到自助取票机，打印纸质电影票，完成取票。
                            <br />2.如现场自助取票机无法打印电影票，请联系影院工作人 员处理。
                            <br />3.凭打印好的纸质电影票，检验入场观影。
                            <br />4.出票后不支持改签、退票。
                        </p>
                    </div>
                </div>
                <div class="fx-center">
                    <van-button :icon="require('@/assets/images/user/user_customer_service.png')" round @click="toCustomer">联系客服</van-button>
                </div>
            </div>
        </div>
        <div class="util-wrap">
            <van-image-preview v-model="show" :images="ticketImages" :start-position="previewIndex">
            </van-image-preview>
        </div>
    </div>
</template>

<script>
import { getOrder, deleteOrder } from '@api/order-request'
import QrCode from '@/components/qr-code'
import ImageList from '@/components/image-list';
import TicketCodeSwiper from '@/components/ticket-code-swiper';

import { isEmpty, map } from 'lodash'
export default {
    data() {
        return {
            orderId: this.$route.query.orderId,
            order: {},
            activeTicket: 0,
            show: false,
            previewIndex: 0,
        }
    },
    computed: {
        ticket() {
            return Object.keys(this.order).length > 0 && this.order.ticketCode ? JSON.parse(this.order.ticketCode) : [];
        },
        ticketValue() {
            let ticketValue = [];
            for (let item of this.ticket) {
                if (item.length == 2) {
                    ticketValue.push(`${item[0].value}%7c${item[1].value}`)
                } else if (item.length == 1) {
                    ticketValue.push(item[0].value)
                }
            }
            return ticketValue;
        },
        ticketImages() {
            let imgs = [];
            if (this.order.ticketImg) {
                imgs = this.order.ticketImg.split(',')
            }
            return imgs;
        },
        chupiaoTime() {
            return this.$store.getters.baseData.chupiaoTime;
        },
        serviceTel() {
            return this.$store.getters.baseData.serviceTel;
        },
        emptyBmh() {
            return isEmpty(this.order.bmhCouponList)
        },
        bmhTicketImages() {
            let images = [];
            if (this.order?.bmhCouponList && this.order?.bmhCouponList.length > 0) {
                images = this.order.bmhCouponList.map((item) => {
                    return {
                        image: item.couponPassPicUrl,
                        mobile: item.mobile,
                        text: item.validPass
                    };
                })
            }
            return images;
        }
    },
    methods: {
        getOrderDetail() {
            getOrder(this.orderId).then((res) => {
                this.order = res;
            })
        },
        toCustomer() {
            window.location.href = `tel: ${this.serviceTel}`;
        },
        confirmDelete() {
            this.$dialog.confirm({
                title: '系统提示',
                message: '确认删除该订单记录吗?',
            }).then(() => {
                let loading = this.$toast.loading('删除中');
                deleteOrder(this.orderId).then(() => {
                    loading.clear();
                    setTimeout(() => {
                        this.$goPage(-1);
                    }, 0);
                }).catch(() => {
                    loading.clear();
                })
            }).catch(() => { });
        },
        onPreview(index) {
            this.show = true;
            this.previewIndex = index;
        },
        goApplyRefund() {
            this.$goPage('request-refund', {
                orderId: this.orderId
            })
        }
    },
    created() {
        this.getOrderDetail();
    },
    watch: {
        ticketImages() {
            this.$nextTick(() => {
                this.activeTicket = this.ticketImages.length > 0 ? 1 : 0
            })
        }
    },
    components: {
        QrCode,
        ImageList,
        TicketCodeSwiper
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	> div {
		padding: 10px;
		> div:nth-of-type(1) {
			color: #fff;
			padding: 8px 0;
			.order_detail-status {
				font-size: 16px;
			}
		}
		.order_detail-content--wrap {
			background-color: #fff;
			border-radius: 4px;
			margin: 15px 0;
			padding: 0 20px;
			> div {
				border-bottom: 2px dashed $border-color;
				padding: 16px 0;
				.order_detail-ticket {
					border: 1px solid #dededf;
					color: $second-color;
					width: 88%;
					padding: 10px;
					font-size: 14px;
					border-radius: 4px;
					margin: 4px;
				}
			}
			.order_detail-name {
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 10px;
			}
		}
	}
}

.refund {
	color: $tips-color;
	text-decoration: underline;
}
</style>
